package net.sergeych.kiloparsec

/**
 * Kiloparsec interface to call remote methods.
 *
 * It is used as for secure layer (L1) as for underlying
 * service layer L0. When using [KiloClientConnection], [KiloServerConnection] and [KiloClient]
 * it always implement secure layer, L1.
 */
interface RemoteInterface {
    /**
     * General channel exception
     */
    open class Exception(text: String, reason: Throwable? = null) : RuntimeException(text, reason)

    /**
     * Is thrown when the channel is closed, in an attempt to execute a command, also to all pending
     * calls (see [call]).
     */
    open class ClosedException(t: String = "connection is closed") : Exception(t)

    open class SecurityException(t: String = "invalid remote id and signature") : ClosedException(t)


    open class InvalidDataException(msg: String="invalid data, can't unpack") : Exception(msg)

    /**
     * Remote call caused an exception thrown while executing it in the remote party. Note that it
     * does not mean the channel state is bad or closed.
     */
    open class RemoteException(
        val code: String,
        val text: String = "remote exception: $code",
        val extra: UByteArray? = null
    ) : Exception(text) {
        constructor(remoteError: Transport.Block.Error) : this(remoteError.code, remoteError.message, remoteError.extra)
    }

    /**
     * Command is not supported by the remote party
     */
    class UnknownCommand : RemoteException("UnknownCommand")

    suspend fun <R> call(cmd: Command<Unit, R>): R = call(cmd, Unit)

    /**
     * Call the remote procedure with specified args and return its result
     */
    suspend fun <A, R> call(cmd: Command<A, R>, args: A): R
}