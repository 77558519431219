package controls

import Client
import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTableCellElement
import unaryPlus

@Composable
fun propLine(text: String, f: ContentBuilder<HTMLTableCellElement>) {
    Tr {
        Th { +text }
        Td { f() }
    }
}

@Composable
fun Profile() {
    LoggedInOnly { me ->
        Di("center-wide") {
            Heading("Ваш профиль:") {
                Table({
                    classes("table", "table-transparent")
                }) {
                    Tbody {
                        propLine("Идентификатор") { +"${me.id}" }
                        propLine("Имя в системе") { +me.fullName }
                        propLine("") {
                            Btn("warning", { Client.logout() }) { +"Выйти из системы" }
                        }
                    }
                }
            }
        }
    }
}
