
package net.sergeych.bintools

/**
 * Variable-length long integer encoding. the MSB (0x80) bit of each byte flags
 * that it is not the last one, and all ncecssary bits are encoded with 7-bit
 * portions, LSB to MSB (big endian of sorts).
 *
 * There is slower but more compact encoding variant, [Smartint] that is better when
 * encoding numbers that needs more than 22 bits. With smaller numbers its either
 * same or even worse, see [Smartint] docs.
 */
object Varint: IntCodec {
    override fun encodeUnsigned(value: ULong, sink: DataSink) {
        var rest = value
        do {
            val x = (rest and 127u).toInt()
            rest = rest shr 7
            if (rest > 0u)
                sink.writeByte(x or 0x80)
            else
                sink.writeByte(x)

        } while (rest > 0u)
    }

    override fun decodeUnsigned(source: DataSource): ULong {
        var result: ULong = 0u
        var count = 0
        while (true) {
            val x = source.readUByte().toInt()
            result = result or ((x and 0x7F).toULong() shl count)
            if ((x and 0x80) == 0)
                break
            count += 7
        }
        return result
    }
}
