import androidx.compose.runtime.Composable
import controls.ActionBtn
import controls.Heading
import controls.LoggedInOnly
import controls.Router
import kotlinx.coroutines.delay
import net.sergeych.morozilko.ApiItem
import net.sergeych.morozilko.cmdDeleteItem
import net.sergeych.morozilko.cmdUpdateItem

@Composable
fun EditItem(item: ApiItem) {
    LoggedInOnly {
        Heading("изменить элемент") {
            EditItemPanel(item, "сохранить",
                extraActions = {
                    ActionBtn("danger","удалить") {
                        delay(1000)
                        if( Client.callCatching(cmdDeleteItem, item).isSuccess)
                            Router.back()
                        else
                            Router.notifyError("не удалось удалить запись")
                    }
                }) {
                it?.let {
                    val r = Client.callCatching(cmdUpdateItem, it)
                    if (r.isSuccess) Router.back()
                    else Router.notifyError("не удалось изменить запись")
                } ?: Router.back()
            }

        }
    }
}