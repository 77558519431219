@file:OptIn(ExperimentalUnsignedTypes::class)

package net.sergeych.bintools

/**
 * Smart variable-length long encoding tools, async. It gives byte-size gain from 64 bits numbers,
 * so it is very useful when encoding big numbers or at least very bui long values. In other cases
 * [Varint] works faster, and extra bits it uses does not play
 *
 * | Bytes sz | varint bits | smartint bits |
 * |:-----:|:------:|:---------:|
 * |   1   |    7   |     6     |
 * |   2   |    14  |    14     |
 * |   3   |    21  |    22     |
 * |   4   |    28  |    29     |
 * |   5   |    35  |    36     |
 * |   6+  |    7*N |   7*N+1   |
 * |   9   |    63  |   64      |
 * |   10  |    64  |   ---     |
 *
 * In other words, except for very small numbers smartint
 * gives 1 data bit gain for the same packed byte size. For example,
 * full size 64 bits number with smartint takes one byte less (9 bytes vs. 10 in Varint).
 *
 * So, except for values in range 32..63 it gives same or better byte size effectiveness
 * than `Varint`. In particular:
 *
 * The effect of it could be interpreted as:
 *
 * | number values | size  |
 * |:--------------|:------:|
 * | 0..31 | same |
 * | 32..63 | worse 1 byte |
 * | 64..1048573 | same |
 * | 1048576..2097151 | 1 byte better |
 * | 2097152..134217727 | same |
 * | 134217728..268435456 | 1 byte better |
 *
 * etc.
 *
 * ## Encoding format
 *
 * Enncoded data could be 1 or more bytes in length. Data are
 * packed as follows:
 *
 * | byte offset | bits range | field |
 * |-------------|------------|-------|
 * | 0 | 0..1 | type |
 * | 0 | 2..7 | v0 |
 * | 1 | 0..7 | v1 (when used) |
 * | 2 | 0..7 | v2 (when used) |
 *
 * Then depending on the `type` field:
 *
 * | type | encoded |
 * |------|---------|
 * | 0 | v0 is the result 0..64 (or -32..32) |
 * | 1 | v0 ## v1 are the result, 14 bits |
 * | 2 | v0  ## v1 ## v2 are the result, 22bits
 * | 3 | v0, ## v1 ## v2 ## (varint encoded rest) |
 *
 * Where `##` means bits concatenation. The bits are interpreted as BIG ENDIAN,
 * for example `24573` will be encoded to `EA FF 02`
 *
 * See also [Varint] for its encoding description.
 *
 */
object Smartint : IntCodec {

    private val v0limit: ULong = (1L shl 6).toULong()
    private val v1limit = (1L shl 14).toULong()
    private val v2limit = (1L shl 22).toULong()

    override fun encodeUnsigned(value: ULong, sink: DataSink) {
        when {
            value < v0limit -> encodeSeq(sink, 0, value)

            value < v1limit -> encodeSeq(
                sink,
                1,
                value and 0x3Fu,
                value shr 6
            )

            value < v2limit -> encodeSeq(
                sink,
                2,
                value and 0x3Fu,
                (value shr 6) and 0xFFu,
                (value shr 14) and 0xFFu,
            )

            else -> {
                encodeSeq(
                    sink,
                    3,
                    value and 0x3Fu,
                    (value shr 6) and 0xFFu,
                    (value shr 14) and 0xFFu,
                )
                Varint.encodeUnsigned(value shr 22, sink)
            }
        }
    }

    private fun encodeSeq(sink: DataSink, type: Int, vararg bytes: ULong) {
        if (bytes.size == 0)
            sink.writeByte(0)
        else {
            if (bytes[0] > v0limit) throw IllegalArgumentException("first number is too big")
            sink.writeUByte(((type and 0x03) or (bytes[0] shl 2).toInt()).toUByte())
            for (x in bytes.drop(1))
                sink.writeUByte(x.toUByte())
        }
    }

    override fun decodeUnsigned(source: DataSource): ULong {
        fun get(): ULong = source.readUByte().toULong()
        val first = get().toUInt()
        var type = (first and 3u).toInt()

        var result: ULong = first.toULong() shr 2
        if (type-- == 0) return result // type 0

        result = result or (get() shl 6)
        if (type-- == 0) return result  // type 1

        result = result or (get() shl 14)
        if (type == 0) return result  // type 2

        return result or (Varint.decodeUnsigned(source) shl 22)
    }

}
