package controls

import HomeScreen
import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun Heading(title: String, content: ContentBuilder<HTMLDivElement>? = null) {
    Row {
        Di("col-auto position-relative w-100") {
            H2 {
                if ( Router.isNotHome() ) {
                    Icon.ArrowLeft.render {
                        style { cursor("pointer") }
                        onClick { Router.back() }
                        classes("me-1")
                    }
                    Text(title)
                } else {

                    Img("/logo1.svg") {
                        style {
                            height(1.8.em)
                            cursor("pointer")
                        }
                        classes("me-1")
                        onClick {
                            Router.push { HomeScreen() }
                        }
                    }

                    Di("d-inline d-sm-none") {
                        Text(title)
                    }
                    Di("d-none d-sm-inline") {
                        Text("Муррозилка: $title")
                    }
                    Di("position-absolute top-0 end-0") {
            //            if( Client.onlineState.isLoggedIn ) {
                        Di("btn btn-default",{
                            onClick { Router.push { Profile() } }
                        }) {
                            Icon.PersonGear.render(1.7.em)
                        }
            //            }
                    }
                }
            }
        }
        content?.let { c ->
            Di("col-md") {
                c()
            }
        }
//        if (window.location.pathname != "/") {
//            Di("col float-end mt-3") {
//                BtnLink("<< назад") { Router.back() }
//            }
//        }
    }
}