import androidx.compose.runtime.Composable
import controls.Di
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P

@Composable
fun GuestHome() {
    Di("center-wide mt-2") {
        H1 { +"Привет! Я Муррозилка!" }
        P {
            +"""Я могу помочь вести учет ваших продуктов со сроком хранения, и предупреждать
            | об истекающих сроках хранения.
        """.trimMargin()
        }
        P {
            +"""Чтобы извещения были надежными, а ваши данные были в безопасности, 
            |я использую телеграм-бота. Вам надо с ним познакомиться, он будет вас
            |предупреждать когда надо, и даст вам безопасную ссылку для доступа 
            |в вашу личную муррозилку!""".trimMargin()
        }
        P {
            +"Чтобы подружиться со мной, старутуйте моего бота в Телеграме, и нажмите там кнопку START:"
        }
        A("https://t.me/morozilko_bot?start=connect", {
            classes("btn", "btn-outline-primary")
        }) {
            +"подключиться к боту"
        }
    }
}