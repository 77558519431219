@file:Suppress("unused")

package net.sergeych.utools

/**
 * Scan the collection and return the first non-null result of the [predicate] on it.
 * If all the elements give null with predicate call, returns null.
 *
 * Note that collection is scanned only to the first non-null predicate result.
 */
fun <T,R>Collection<T>.firstNonNull(predicate: (T)->R?): R? {
    for( x in this ) predicate(x)?.let { return it }
    return null
}