package net.sergeych.kiloparsec.adapter

import io.ktor.client.*
import io.ktor.client.plugins.websocket.*
import io.ktor.http.*
import io.ktor.websocket.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.cancel
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.launch
import net.sergeych.crypto2.SigningKey
import net.sergeych.kiloparsec.KiloClient
import net.sergeych.kiloparsec.KiloConnectionData
import net.sergeych.kiloparsec.KiloInterface
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.exception
import net.sergeych.mp_logger.info
import net.sergeych.mp_logger.warning
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.tools.AtomicCounter

private val counter = AtomicCounter()

fun <S>websocketClient(
    path: String,
    clientInterface: KiloInterface<S> = KiloInterface(),
    client: HttpClient = HttpClient { install(WebSockets) },
    secretKey: SigningKey.Secret? = null,
    sessionMaker: () -> S = {
        @Suppress("UNCHECKED_CAST")
        Unit as S
    },
): KiloClient<S> {
    var u = Url(path)
    if (u.encodedPath.length <= 1)
        u = URLBuilder(u).apply {
            encodedPath = "/kp"
        }.build()

    return KiloClient(clientInterface, secretKey) {
        val input = Channel<UByteArray>()
        val output = Channel<UByteArray>()
        val job = globalLaunch {
            val log = LogTag("KC:${counter.incrementAndGet()}")
            client.webSocket({
                url.protocol = u.protocol
                url.host = u.host
                url.port = u.port
                url.encodedPath = u.encodedPath
                url.parameters.appendAll(u.parameters)
                    log.info { "kiloparsec server URL: $url" }
            }) {
                try {
                    log.info { "connected to the server" }
                    println("SENDING!!!")
                    send("Helluva")
                    launch {
                        for (block in output) {
                            send(block.toByteArray())
                        }
                        log.info { "input is closed, closing the websocket" }
                        cancel()
                    }
                    for (f in incoming) {
                        if (f is Frame.Binary) {
                            input.send(f.readBytes().toUByteArray())
                        } else {
                            log.warning { "ignoring unexpected frame of type ${f.frameType}" }
                        }
                    }
                }
                catch(_:CancellationException) {
                }
                catch(t: Throwable) {
                    log.exception { "unexpected error" to t }
                }
                log.info { "closing connection" }
            }
        }
        val device = ProxyDevice(input,output) {
            input.close()
            // we need to explicitly close the coroutine job, or it can hang for a long time
            // leaking resources.
            job.cancel()
        }
        KiloConnectionData(device, sessionMaker())
    }
}
