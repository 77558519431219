package controls

import Client
import androidx.compose.runtime.Composable
import net.sergeych.morozilko.ApiUser

@Composable
fun LoggedInOnly(f: @Composable (ApiUser) -> Unit) {
    val me = Client.me
    if (me == null) {
        console.error("Logged in required!")
        Router.home()
    }
    else f(me)
}