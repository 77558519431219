import androidx.compose.runtime.*
import controls.Di
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLImageElement
import tools.randomId
import kotlin.math.roundToInt
import kotlin.random.Random

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun Flake(n: Int) {

    var wMax by remember { mutableStateOf(window.innerWidth) }
    var hMax by remember { mutableStateOf(window.innerHeight) }

    val sx = remember { Random.nextFloat() * 3 + -1f }
    val sy = remember { Random.nextFloat() * 5 + 2f }
    val sk = remember { Random.nextFloat() }

    // when recompose on window size, we want flakes to keep their positions
    var x = remember { Random.nextInt(0, wMax).toFloat() }
    var y = remember { Random.nextInt(0, hMax).toFloat() }

    val id = remember { randomId(32) }

    val directionLeft = remember { Random.nextBoolean() }
    val rotationTime = remember { Random.nextFloat() * 5 + 2 }

    val size = remember { 17 + 31 * sk }
    LaunchedEffect(true) {
        launch {
            while (isActive) {
                delay(60)
                try {
                    val i = document.getElementById(id) as HTMLImageElement
                    x += sx
                    if( x > wMax ) x = -size
                    y += sy
                    if( y > hMax ) y = -size
                    i.style.left = "${x.roundToInt()}px"
                    i.style.top = "${y.roundToInt()}px"
                } catch (t: Throwable) {
                    t.printStackTrace()
                    throw t
                }
            }
        }
    }

//    println("compose $id: $x, $y, $sx, $sy")
    LaunchedEffect(n) {
        window.addEventListener("resize", {
            wMax = window.innerWidth
            hMax = window.innerHeight
        })
    }

    Img(src = "/snowflakes/sf$n.svg") {
        id(id)
        style {
            width(size.px)
            height(size.px)
            position(Position.Fixed)
            animation(if (directionLeft) "rotateLeft" else "rotateRight") {
                duration(rotationTime.s)
                timingFunction(AnimationTimingFunction.Linear)
                iterationCount(Int.MAX_VALUE)
            }
            left(x.px)
            top(y.px)
        }
    }
}

@Composable
fun template(f: ContentBuilder<HTMLDivElement>) {
    Di("container") {
//        Flake(2)
        for (n in 0..12) Flake(n)
        for (n in 0..12) Flake(n)
        f()
    }
}