package net.sergeych.kiloparsec

/**
 * Minimal data to create kiloparsec connection: transport device and a new session object.
 */
data class KiloConnectionData<S>(
    val device: Transport.Device,
    val session: S
)

/**
 * callback that creates new [Transport.Device] and session objects for Kiloparsec connections.
 */
typealias ConnectionDataFactory<S> = suspend ()->KiloConnectionData<S>
