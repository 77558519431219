package controls

import HomeScreen
import androidx.compose.runtime.*
import kotlinx.browser.document
import kotlinx.coroutines.flow.MutableSharedFlow
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.ElementScope
import org.w3c.dom.HTMLDivElement
import template
import tools.randomId
import unaryPlus

class Toast(val text: String,val variant: Type = Type.Info,val id: String = "T:${randomId(9)}") {
    enum class Type {
        Info, Warning, Danger
    }
}

class PopupScope(val popupId: Long, parent: ElementScope<HTMLDivElement>) : ElementScope<HTMLDivElement> by parent {
    fun closePopup() {
        Router.closePopup(popupId)
    }
}

typealias PopupBuilder = @Composable PopupScope.()->Unit
class Popup(val popupId: Long, val builder: PopupBuilder)
object Router {

    private var lastPopipId = 0L

    private val stack =
        mutableStateListOf<ContentBuilder<HTMLDivElement>>()

    private val popups =
        mutableStateListOf<Popup>()

    fun back() {
        stack.removeLast()
    }

    fun push(f: ContentBuilder<HTMLDivElement>) {
        stack.add(f)
    }

    fun home() {
        stack.clear()
    }

    fun openPopup(builder: PopupBuilder) {
        popups.add(Popup(++lastPopipId, builder))
    }
    fun closePopup(id: Long) {
        val index = popups.indexOfFirst { it.popupId == id }
        if (index >= 0) popups.removeAt(index)
    }

    private val toasts = MutableSharedFlow<Toast>()

    @Composable
    fun render() {
        var nextToast by mutableStateOf<Toast?>(null)
        template {
            if (stack.isEmpty())
                HomeScreen()
            else stack.last().invoke(this)
            popups.lastOrNull()?.let {
                Di("dim-screen") {}
                it.builder.invoke(PopupScope(it.popupId, this))
            }
            nextToast?.let { toast ->
                Di("toast-container position-fixed bottom-0 end-0 p-3") {
                    Di("toast", {
                        id(toast.id)
                        classes(when(toast.variant) {
                            Toast.Type.Info -> "text-bg-primary"
                            Toast.Type.Warning -> "text-bg-warning"
                            Toast.Type.Danger -> "text-bg-danger"
                        })
                    }) {
                        Di("toast-body") { +toast.text }
                    }
                }
                LaunchedEffect(toast) {
                    document.getElementById(toast.id)?.let { e ->
                        js("bootstrap.Toast.getOrCreateInstance(e).show()")
                    }
                }
            }
        }
        LaunchedEffect(true) {
            toasts.collect {
                println("collected a toast: $it")
                nextToast = it
            }
        }
    }

    fun notifyError(msg: String) {
        console.error(msg)
        globalLaunch {
            toasts.emit(Toast(msg, Toast.Type.Danger))
        }
    }
    fun notifySuccess(msg: String) {
        console.log(msg)
        globalLaunch {
            toasts.emit(Toast(msg, Toast.Type.Info))
        }
    }

    fun isNotHome(): Boolean = stack.isNotEmpty()
}