import androidx.compose.runtime.*
import controls.Btn
import controls.DateField
import controls.InputState
import controls.TextField
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.daysUntil
import kotlinx.datetime.minus
import net.sergeych.morozilko.ApiItem
import net.sergeych.morozilko.nowDate
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.Hr

@Composable
fun EditItemPanel(
    item: ApiItem, okText: String,
    extraActions: (@Composable ()->Unit)? = null,
    f: suspend (ApiItem?) -> Unit,
) {
    var name by remember { mutableStateOf(item.name) }
    var qty by remember { mutableStateOf(item.qty) }
    var expiresAt by remember {
        mutableStateOf(item.expiresAt)
    }
    var notifiationWasSet = remember { item.notifyAt != null }
    var notifyAt by remember { mutableStateOf(item.notifyAt ?: expiresAt.minus(3, DateTimeUnit.DAY)) }

    if (notifyAt < nowDate()) notifyAt = expiresAt

    TextField(name, "название", InputState.require(name)) { name = it }
    TextField(qty, "количество", InputState.require(qty)) { qty = it }

    DateField(expiresAt, "годен до:") {
        expiresAt = it
        if (notifiationWasSet) {
            if (expiresAt > notifyAt)
                notifyAt = calculateSmartNotification(expiresAt)
        } else {
            notifyAt = calculateSmartNotification(expiresAt)
        }
    }
    DateField(notifyAt, "предупредить:") {
        notifyAt = it
        notifiationWasSet = true
    }
    val ndays = notifyAt.daysUntil(expiresAt)
    when {
        ndays == 0 -> +"в день истечения срока годности"
        ndays < 0 -> {}
        else -> +"за $ndays дн."
    }

    Hr {}
    val ok = name.isNotBlank() && qty.isNotBlank()

    var inProgress by remember { mutableStateOf(false) }

    Btn("primary", {
        if (ok) {
            inProgress = true
            globalLaunch {
                try {
                    f(ApiItem(name, qty, expiresAt, notifyAt, item.id))
                } finally {
                    inProgress = false
                }
            }
        }
    }, {
        if (!ok || inProgress) classes("disabled")
    }) {
        if (inProgress)
            Spinner()
        else
            +okText
    }
    extraActions?.let { it() }
}