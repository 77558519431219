package controls

import Spinner
import androidx.compose.runtime.*
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.w3c.dom.HTMLDivElement
import unaryPlus

@Composable
fun Btn(
    style: String = "btn-default",
    onClick: (() -> Unit)? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    contentBuilder: ContentBuilder<HTMLDivElement>,
) {
    val s = if (!style.startsWith("btn-")) "btn-$style" else style
    Di("btn $s me-2", {
        attrs?.invoke(this)
        onClick?.let { onClick { it() } }
    }, contentBuilder)
}

@Composable
fun ActionBtn(
    style: String = "btn-default",
    text: String? = null,
    icon: Icon? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: ContentBuilder<HTMLDivElement>? = null,
    onClick: suspend (() -> Unit),
) {
    var busy by remember { mutableStateOf(false) }
    Btn(style,{
        busy = true
        globalLaunch {
            try {
                onClick()
            }
            catch(t: Throwable) {
                Router.notifyError("неожиданная ошибка: ${t.message}")
            }
            finally {
                busy = false
            }
        }
    },{
        attrs?.let { it() }
        if( busy ) classes("disabled")
    }) {
        if( busy ) Spinner()
        else {
            content?.let { it() }
                ?: run {
                    icon?.let {
                        it.render({
                            classes("me-2")
                        })
                    }
                    text?.let { +it }
                }
        }
    }
}

@Suppress("unused")
@Composable
fun PopupScope.ClosePopupButton(
    className: String = "primary",
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    f: ContentBuilder<HTMLDivElement>,
) {
    Btn(className, { closePopup() }, attrs, f)
}