package net.sergeych.morozilko

import kotlinx.datetime.*
import kotlinx.serialization.Serializable

@Suppress("unused")
@Serializable
data class ApiItem(
    val name: String,
    val qty: String,
    val expiresAt: LocalDate,
    val notifyAt: LocalDate?,
    val id: Long = 0,
) {

    data class Partial(
        val name: String?,
        val qty: String?,
        val expiresAt: LocalDate?,
        val notifyAt: LocalDate?,
    ) {
        fun toItemOrNull() =
            if (name != null && qty != null && expiresAt != null && notifyAt != null)
                ApiItem(name, qty, expiresAt, notifyAt)
            else
                null
    }


    companion object {
        fun parse(source: String): Partial {
            var src = source
            val qty = Amount.parse(source)?.let {
                src = it.processedSource
                it.result
            }
            val expiration = extractRelativeOrAbsoluteDate(src)?.let {
                src = it.processedSource
                it.result
            }

            val notifyAt = expiration?.let { xd ->
                val now = Clock.System.now()
                val tz = TimeZone.currentSystemDefault()
                val daysLeft = (xd.atStartOfDayIn(tz) - now).inWholeDays
                when(daysLeft) {
                    in 0..3 -> xd
                    in 3..14 -> xd.minus(3, DateTimeUnit.DAY)
                    else -> xd.minus(7, DateTimeUnit.DAY)
                }
            }
            return Partial(src, qty?.toString(), expiration, notifyAt )
        }
    }

}