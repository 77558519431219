package controls

class InputState(
    val isError: Boolean = false,
    val isSuccess: Boolean = false,
    val errorText: String? = null,
    val successText: String? = null,
) {
    companion object {
        fun success(msg: String? = null) = InputState(false, true, null, msg)
        fun error(msg: String?) = InputState(true, false, msg, null)

        val neutral = InputState()

        fun require(value: String, message: String? = null) =
            if (value.isBlank()) error(message)
            else success()

    }
}

