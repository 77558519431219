package net.sergeych.bintools

import kotlinx.serialization.serializer
import net.sergeych.bipack.BipackDecoder
import net.sergeych.bipack.BipackEncoder
import kotlin.reflect.KProperty
import kotlin.reflect.KType
import kotlin.reflect.typeOf


/**
 * Generic storage of binary content. PArsec uses boss encoding to store everything in it
 * in a convenient way. See [KVStorage.stored], [KVStorage.invoke] and
 * [KVStorage.optStored] delegates. The [MemoryKVStorage] is an implementation that stores
 * values in memory, allowing to connect some other (e.g. persistent storage) later in a
 * completely transparent way. It can also be used to cache values on the fly.
 *
 * Also, it is possible to use [read] and [write] where delegated properties
 * do not fit well.
 */
@Suppress("unused")
interface KVStorage {
    operator fun get(key: String): ByteArray?
    operator fun set(key: String, value: ByteArray?)

    /**
     * Check whether key is in storage.
     * Default implementation uses [keys]. You may override it for performance
     */
    operator fun contains(key: String): Boolean = key in keys

    val keys: Set<String>


    /**
     * Get number of object in the storage
     * Default implementation uses [keys]. You may override it for performance
     */
    val size: Int get() = keys.size

    /**
     * Clears all objects in the storage
     * Default implementation uses [keys]. You may override it for performance
     */
    fun clear() {
        for (k in keys) this[k] = null
    }

    /**
     * Default implementation uses [keys]. You may override it for performance
     */
    fun isEmpty() = size == 0

    /**
     * Default implementation uses [keys]. You may override it for performance
     */
    fun isNotEmpty() = size != 0

    /**
     * Add all elements from another storage, overwriting any existing
     * keys.
     */
    fun addAll(other: KVStorage) {
        for (k in other.keys) {
            this[k] = other[k]
        }
    }

    /**
     * Delete element by key
     */
    fun delete(key: String) {
        set(key, null)
    }
}

/**
 * Write
 */
inline fun <reified T: Any>KVStorage.write(key: String,value: T) {
    this[key] = BipackEncoder.encode(value)
}

inline fun <reified T:Any>KVStorage.read(key: String): T? =
    this[key]?.let { BipackDecoder.decode(it) }


inline operator fun <reified T> KVStorage.invoke(defaultValue: T,overrideName: String? = null) =
    KVStorageDelegate<T>(this, typeOf<T>(), defaultValue, overrideName)

inline fun <reified T> KVStorage.stored(defaultValue: T, overrideName: String? = null) =
    KVStorageDelegate<T>(this, typeOf<T>(), defaultValue, overrideName)
inline fun <reified T> KVStorage.optStored(overrideName: String? = null) =
    KVStorageDelegate<T?>(this, typeOf<T?>(), null, overrideName)

class KVStorageDelegate<T>(
    private val storage: KVStorage,
    type: KType,
    private val defaultValue: T,
    private val overrideName: String? = null,
) {

    private fun name(property: KProperty<*>): String = overrideName ?: property.name

    private var cachedValue: T = defaultValue
    private var cacheReady = false
    private val serializer = serializer(type)

    @Suppress("UNCHECKED_CAST")
    operator fun getValue(thisRef: Any?, property: KProperty<*>): T {
        if (cacheReady) return cachedValue
        val data = storage.get(name(property))
        println("Got data: ${data?.toDump()}")
        if (data == null)
            cachedValue = defaultValue
        else
            cachedValue = BipackDecoder.decode(data.toDataSource(), serializer) as T
        cacheReady = true
        return cachedValue
    }

    operator fun setValue(thisRef: Any?, property: KProperty<*>, value: T) {
//        if (!cacheReady || value != cachedValue) {
            cachedValue = value
            cacheReady = true
            println("set ${name(property)} to ${BipackEncoder.encode(serializer, value).toDump()}")
            storage[name(property)] = BipackEncoder.encode(serializer, value)
//        }
    }
}

/**
 * Memory storage. Allows connecting to another storage (e.g. persistent one) at come
 * point later in a transparent way.
 */
class MemoryKVStorage(copyFrom: KVStorage? = null) : KVStorage {

    // is used when connected:
    private var underlying: KVStorage? = null

    // is used while underlying is null:
    private val data = mutableMapOf<String, ByteArray>()

    /**
     * Connect some other storage. All existing data will be copied to the [other]
     * storage. After this call all data access will be routed to [other] storage.
     */
    @Suppress("unused")
    fun connectToStorage(other: KVStorage) {
        other.addAll(this)
        underlying = other
        data.clear()
    }


    /**
     * Get  data from either memory or a connected storage, see [connectToStorage]
     */
    override fun get(key: String): ByteArray? {
        underlying?.let {
            return it[key]
        }
        return data[key]
    }

    /**
     * Put data to memory storage or connected storage if [connectToStorage] was called
     */
    override fun set(key: String, value: ByteArray?) {
        underlying?.let { it[key] = value } ?: run {
            if (value != null) data[key] = value
            else data.remove(key)
        }
    }

    /**
     * Checks the item exists in the memory storage or connected one, see[connectToStorage]
     */
    override fun contains(key: String): Boolean {
        underlying?.let { return key in it }
        return key in data
    }

    override val keys: Set<String>
        get() = underlying?.keys ?: data.keys

    override fun clear() {
        underlying?.clear() ?: data.clear()
    }

    init {
        copyFrom?.let { addAll(it) }
    }
}

/**
 * Create per-platform default named storage.
 *
 * - In the browser, it uses the `Window.localStorage` prefixing items
 *   by a string containing the [name]
 *
 * - In the JVM environment it uses folder-based storage on the file system. The name
 *   is considered to be a folder name (the whole path which will be automatically created)
 *   using the following rules:
 *    - when the name starts with slash (`/`) it is treated as an absolute path to a folder
 *    - when the name contains slash, it is considered to be a relative folder to the
 *      `User.home` directory, like "`~/`" on unix systems.
 *    - otherwise, the folder will be created in "`~/.local_storage`" parent directory
 *      (which also will be created if needed).
 *
 *  - For the native platorms it is not yet implemented (but will be soon).
 *
 *  See [DataKVStorage] and [DataProvider] to implement a KVStorage on filesystems and like,
 *  and `FileDataProvider` class on JVM target.
 */
expect fun defaultNamedStorage(name: String): KVStorage