package net.sergeych.bintools

class CRC32Source(
    val source: DataSource,
    polynomial: UInt = 0x04C11DB7U
): DataSource {

    private val checksum = CRC32(polynomial)
    override fun readByte(): Byte = source.readByte().also {
        checksum.update(it.toUByte())
    }

    override fun readBytes(size: Int): ByteArray = source.readBytes(size).also {
        checksum.update(it.toUByteArray())
    }

    val crc: UInt get() = checksum.value
}
