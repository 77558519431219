package net.sergeych.utools

import kotlinx.serialization.KSerializer
import kotlinx.serialization.serializer
import net.sergeych.bintools.toDataSource
import net.sergeych.bipack.BipackDecoder
import net.sergeych.bipack.BipackEncoder

/**
 * Effectively pack anyk nullable object. The result could be effectively packed
 * in turn as a part of a more complex structure.
 *
 * To avoid packing non-null mark,
 * we use a zero-size array, which, if in turn encoded, packs into a single
 * zero byte. Thus, we avoid extra byte spending for unnecessary null
 * check.
 */
inline fun <reified T> pack(element: T?): UByteArray = pack(serializer<T>(), element)

/**
 * Unpack nullable data packed with [pack]
 */
inline fun <reified T: Any?> unpack(encoded: UByteArray): T =
    unpack(serializer<T>(), encoded)

/**
 * Effectively pack anyk nullable object. The result could be effectively packed
 * in turn as a part of a more complex structure.
 *
 * To avoid packing non-null mark,
 * we use a zero-size array, which, if in turn encoded, packs into a single
 * zero byte. Thus, we avoid extra byte spending for unnecessary null
 * check.
 */
fun <T>pack(serializer: KSerializer<T>, element: T?): UByteArray =
    if (element == null) ubyteArrayOf()
    else BipackEncoder.encode(serializer,element).toUByteArray()


/**
 * Unpack nullable data packed with [pack]
 */
@Suppress("UNCHECKED_CAST")
fun <T: Any?> unpack(serializer: KSerializer<T>, encoded: UByteArray): T =
    if (encoded.isEmpty()) null as T
    else BipackDecoder.decode(encoded.toByteArray().toDataSource(),serializer)
