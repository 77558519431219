@file:Suppress("unused")

package net.sergeych.morozilko

import kotlinx.serialization.Serializable
import net.sergeych.kiloparsec.command

val cmdActivateAuthToken by command<String,ApiUser?>()
val cmdLogin by command<String,ApiUser?>()
val cmdLogout by command<Unit,Unit>()
val cmdDeleteProfile by command<Unit,Unit>()

val cmdListItems by command<Unit,List<ApiItem>>()
val cmdCreateItem by command<ApiItem,ApiItem>()
val cmdUpdateItem by command<ApiItem,ApiItem>()
val cmdDeleteItem by command<ApiItem,Unit>()

@Serializable
data class ApiVersion(
    val version: String,
    val service: String,
    val status: String
)

val cmdVersion by command<Unit,ApiVersion>()
val cmdUpdateTzOffset by command<Int,Unit>()