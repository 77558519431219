import androidx.compose.runtime.Composable
import controls.Heading
import controls.LoggedInOnly
import controls.Router
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.plus
import net.sergeych.morozilko.ApiItem
import net.sergeych.morozilko.cmdCreateItem
import net.sergeych.morozilko.nowDate

@Composable
fun AddItem() {
    LoggedInOnly {
        Heading("Создание записи")
        val item = ApiItem("", "", nowDate().plus(10, DateTimeUnit.DAY), null)
        EditItemPanel(item, "создать") { newItem ->
            newItem?.let {
                val r = Client.callCatching(cmdCreateItem, newItem)
                if (r.isSuccess) {
                    Router.notifySuccess("запись создана")
                    Router.home()
                } else Router.notifyError("не удалось создать запись")
            }
        }
    }
}


