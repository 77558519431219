package net.sergeych.crypto2

import kotlinx.serialization.Serializable

@Serializable
class Seal(
    val publicKey: SigningKey.Public,
    val signature: UByteArray
) {
    inline fun verify(message: UByteArray) = publicKey.verify(signature, message)
}