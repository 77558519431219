package net.sergeych.kiloparsec

import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.isActive
import net.sergeych.crypto2.SigningKey
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.Loggable
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.exception
import net.sergeych.mp_tools.globalLaunch

/**
 * The auto-connecting client that reconnects to the kiloparsec server
 * and maintain connection state flow. Client factory launches a disconnected
 * set of coroutines to support automatic reconnection, so you _must_ [close]
 * it manually when it is not needed, otherwise it will continue to reconnect.
 */
class KiloClient<S>(
    localInterface: KiloInterface<S>,
    secretKey: SigningKey.Secret? = null,
    connectionDataFactory: ConnectionDataFactory<S>,
) : RemoteInterface,
    Loggable by LogTag("CLIF") {

    val _state = MutableStateFlow(false)

    /**
     * State flow that shows the current state of an auto-connecting client. Use it
     * to authenticate a client on connection restore, for example.
     */
    @Suppress("unused")
    val state = _state.asStateFlow()

    private var deferredClient = CompletableDeferred<KiloClientConnection<S>>()

    private val job =
        globalLaunch {
            debug { "starting connector" }
            while (isActive) {
                try {
                    debug { "getting connection" }
                    val kc = connectionDataFactory()
                    debug { "get device and session" }
                    val client = KiloClientConnection(localInterface, kc,secretKey)
                    deferredClient.complete(client)
                    client.run {
                        _state.value = it
                    }
                    debug { "client run finished" }
                } catch (_: RemoteInterface.ClosedException) {
                    debug { "remote closed" }
                    delay(1000)
                } catch (_: CancellationException) {
                    debug { "cancelled" }
                } catch (t: Throwable) {
                    exception { "unexpected exception" to t }
                    delay(1000)
                }
                _state.value = false
                if (deferredClient.isActive)
                    deferredClient = CompletableDeferred()
                delay(1000)
            }
        }

    fun close() {
        job.cancel()
        debug { "client is closed" }
    }

    override suspend fun <A, R> call(cmd: Command<A, R>, args: A): R = deferredClient.await().call(cmd, args)

    /**
     * Current session token. This is a per-connection unique random value same on the client and server part so
     * it could be used as a nonce to pair MITM and like attacks, be sure that the server is actually
     * working, etc.
     */
    suspend fun token() = deferredClient.await().token()

    /**
     * Remote party shared key ([SigningKey.Public]]), could be used ti ensure server is what we expected and
     * there is no active MITM attack.
     *
     * Non-null value means the key was successfully authenticated, null means remote party did not provide
     * a key. Connection is established either with a properly authenticated key or no key at all.
     */
    @Suppress("unused")
    suspend fun remoteId() = deferredClient.await().remoteId()

    companion object {
        class Builder<S>() {

            private var interfaceBuilder: (KiloInterface<S>.() -> Unit)? = null
            private var sessionBuilder: (() -> S) = {
                @Suppress("UNCHECKED_CAST")
                Unit as S
            }
            private var connectionBuilder: (suspend () -> Transport.Device)? = null

            var secretIdKey: SigningKey.Secret? = null

            /**
             * Build local command implementations (remotely callable ones), exception
             * class handlers, etc.
             */
            fun local(f: KiloInterface<S>.() -> Unit) {
                interfaceBuilder = f
            }

            /**
             * Create a new session object, otherwise Unit session will be used
             */
            fun session(f: () -> S) {
                sessionBuilder = f
            }

            fun connect(f: suspend () -> Transport.Device) {
                connectionBuilder = f
            }

            internal fun build(): KiloClient<S> {
                val i = KiloInterface<S>()
                interfaceBuilder?.let { i.it() }
                val connector = connectionBuilder ?: throw IllegalArgumentException("connect handler was not set")
                return KiloClient(i,secretIdKey) {
                    KiloConnectionData(connector(),sessionBuilder())
                }
            }
        }

        /**
         * Call the secure remote command when the secure connection is established. Note that
         * it might fail on disconnect. We do not automatically repeat command on disconnect
         * as actual services might need identification on reconnecting.
         */
        operator fun <S> invoke(f: Builder<S>.() -> Unit): KiloClient<S> {
            return Builder<S>().also { it.f() }.build()
        }
    }
}
