import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.daysUntil
import kotlinx.datetime.minus
import net.sergeych.morozilko.nowDate

fun calculateSmartNotification(expiresAt: LocalDate): LocalDate {
    val now = nowDate()
    val days = now.daysUntil(expiresAt)
    if (days <= 0) return expiresAt
    if (days < 16) return expiresAt.minus(7, DateTimeUnit.DAY)
    if (days < 61) return expiresAt.minus(14, DateTimeUnit.DAY)
    return expiresAt.minus(1, DateTimeUnit.MONTH)
}