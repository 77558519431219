import androidx.compose.runtime.Composable
import controls.Di
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.dom.ContentBuilder
import org.w3c.dom.HTMLDivElement

@Composable
fun HomeScreen() {
    when (val s = Client.onlineState) {
        is OnlineState.LoggedIn -> UserHome(s.user)
        OnlineState.Checking -> WaitPanel("Муррозилка проверяет соединение")
        OnlineState.NotLoggedIn -> GuestHome()
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun CenterPanel(title: ContentBuilder<HTMLDivElement>?=null,f: ContentBuilder<HTMLDivElement>) {
    Di("center-panel card", {
//        style {
//            position(Position.Fixed)
//            top(50.percent)
//            left(50.percent)
//            transform { translate(-50.percent,-50.percent) }
//        }
    }) {
        title?.let {
            Di("card-title") {
                it()
            }
        }
        Di("card-body") { f() }
    }
}

