package net.sergeych.crypto2

import com.ionspin.kotlin.crypto.LibsodiumInitializer
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

private var isReady = false
private val readyAccess = Mutex()

/**
 * Library initialization: should be called before all other calls.
 * It is safe and with little performance penalty to call it multiple times.
 */
suspend fun initCrypto() {
    // faster to check with no lock
    if( !isReady) {
        readyAccess.withLock {
            // recheck with lock, it could be ready by now
            if( !isReady ) {
                LibsodiumInitializer.initialize()
                isReady = true
            }
        }
    }
}


