package net.sergeych.tools

/**
 * Multiplatform (JS and battery included) atomically mutable value.
 * Actual value can be either changed in a block of [mutuate] when
 * new value _depends on the current value_ or use a same [value]
 * property that is thread-safe where there are threads and just safe
 * otherwise ;)
 */
open class AtomicValue<T>(initialValue: T) {
    var actualValue = initialValue
    val op = ProtectedOp()

    /**
     * Change the value: get the current and set to the returned, all in the
     * atomic operation. All other mutating requests including assigning to [value]
     * will be blocked and queued.
     * @return result of the mutation. Note that immediate call to property [value]
     *      could already return modified bu some other thread value!
     */
    fun mutate(mutator: (T) -> T): T = op {
        actualValue = mutator(actualValue)
        actualValue
    }

    /**
     * Atomic get or set the value. Atomic get means if there is a [mutate] in progress
     * it will wait until the mutation finishes and then return the correct result.
     */
    var value: T
        get() = op { actualValue }
        set(value) {
            mutate { value }
        }
}