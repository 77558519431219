package net.sergeych.kiloparsec

import com.ionspin.kotlin.crypto.keyexchange.KeyExchangeSessionKeyPair
import com.ionspin.kotlin.crypto.secretbox.SecretBox
import com.ionspin.kotlin.crypto.secretbox.crypto_secretbox_NONCEBYTES
import com.ionspin.kotlin.crypto.util.decodeFromUByteArray
import com.ionspin.kotlin.crypto.util.encodeToUByteArray
import kotlinx.serialization.Serializable
import net.sergeych.bintools.toDataSource
import net.sergeych.bipack.BipackDecoder
import net.sergeych.bipack.BipackEncoder
import net.sergeych.crypto2.DecryptionFailedException
import net.sergeych.crypto2.SigningKey
import net.sergeych.crypto2.randomBytes
import net.sergeych.crypto2.randomUInt
import net.sergeych.tools.ProtectedOp
import net.sergeych.utools.pack
import net.sergeych.utools.unpack
import org.komputing.khash.keccak.Keccak
import org.komputing.khash.keccak.KeccakParameter
import kotlin.math.roundToInt

/**
 * Parameters used in secured local and remote interfaces, etc. Actual values
 * are calculated in [KiloServerConnection] and [KiloClientConnection] by
 * exchanging keys, then used to encrypt/decrypt calls and results and create
 * [KiloScope] with a proper session object.
 *
 * __important: parameters' calculation algorithms are different on the server and
 * client side, so you need to provide proper [isServer] value!__
 */
data class KiloParams<S>(
    val isServer: Boolean,
    val transport: RemoteInterface,
    val sessionKeyPair: KeyExchangeSessionKeyPair,
    val scopeSession: S,
    val remoteIdentity: SigningKey.Public?,
    val remoteTransport: RemoteInterface
) {
    @Serializable
    data class Package(
        val nonce: ULong,
        val encryptedMessage: UByteArray,
    )

    @Serializable
    data class FilledData(
        val message: UByteArray,
        val fill: UByteArray,
    )

    private var nonce = 0UL

    val scope: KiloScope<S> by lazy {
        object : KiloScope<S> {
            override val session = scopeSession
            override val remote: RemoteInterface = remoteTransport
            override val sessionToken: UByteArray = token
            override val remoteIdentity: SigningKey.Public? = this@KiloParams.remoteIdentity
        }
    }

    val token: UByteArray by lazy {
        val base = if (isServer) sessionKeyPair.sendKey + sessionKeyPair.receiveKey
        else sessionKeyPair.receiveKey + sessionKeyPair.sendKey
        Keccak.digest(
            base.toByteArray(), KeccakParameter.KECCAK_256
        ).toUByteArray().sliceArray(0..<crypto_secretbox_NONCEBYTES)
    }

    private val sendBase by lazy {
        Keccak.digest(
            sessionKeyPair.sendKey.toByteArray(), KeccakParameter.KECCAK_256
        ).toUByteArray().sliceArray(0..<crypto_secretbox_NONCEBYTES)
    }

    private val receiveBase by lazy {
        Keccak.digest(
            sessionKeyPair.receiveKey.toByteArray(), KeccakParameter.KECCAK_256
        ).toUByteArray().sliceArray(0..<crypto_secretbox_NONCEBYTES)
    }

    private inline fun encodeNonce(base: UByteArray, nonce: ULong): UByteArray {
        val result = base.copyOf()
        var x = nonce
        var i = 0
        while (x > 0u) {
            result[i] = result[i] xor (x and 0xFFu).toUByte()
            x = x shr 8
            i++
        }
        return result
    }

    private inline fun encodeSendNonce(nonce: ULong): UByteArray = encodeNonce(sendBase, nonce)
    private inline fun encodeReceiveNonce(nonce: ULong): UByteArray = encodeNonce(receiveBase, nonce)


    fun encrypt(plainText: String): UByteArray = encrypt(plainText.encodeToUByteArray())

    private val proptectedOp = ProtectedOp()

    /**
     * Encrypt using send keys and proper nonce
     */
    fun encrypt(message: UByteArray, fillFactor: Float = 0f): UByteArray {
        val fill: UByteArray = if (fillFactor > 0f)
            randomBytes(randomUInt((message.size * fillFactor).roundToInt()))
        else
            ubyteArrayOf()

        val withFill = BipackEncoder.encode(FilledData(message, fill)).toUByteArray()

        val n = proptectedOp { nonce++ }

        return pack(
            Package(n, SecretBox.easy(withFill, encodeSendNonce(n), sessionKeyPair.sendKey))
        )
    }

    fun decryptString(cipherText: UByteArray): String = decrypt(cipherText).decodeFromUByteArray()
    fun decrypt(encryptedMessage: UByteArray): UByteArray {
        val p: Package = BipackDecoder.decode(encryptedMessage.toDataSource())
        try {
            return unpack<FilledData>(
                SecretBox.openEasy(
                    p.encryptedMessage,
                    encodeReceiveNonce(p.nonce),
                    sessionKeyPair.receiveKey
                )
            ).message
        } catch (_: com.ionspin.kotlin.crypto.secretbox.SecretBoxCorruptedOrTamperedDataExceptionOrInvalidKey) {
            throw DecryptionFailedException()
        }
    }
}