package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.datetime.LocalDate
import net.sergeych.morozilko.nowDate
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import unaryPlus

private @Composable
fun OptMonth(value: Int, name: String, selectedValue: Int) {
    Option(value.toString(), { if (value == selectedValue) selected() }) { +name }
}

@Composable
fun DateField(
    date: LocalDate, label: String, state: InputState = InputState.neutral,
    setter: (LocalDate) -> Unit,
) {
    var day = date.dayOfMonth
    var month = date.monthNumber
    var year = date.year
    var invalid by mutableStateOf(false)

    fun tryCreateDate() {
        try {
            LocalDate(year, month, day).also {
                setter(it)
            }
        } catch (_: IllegalArgumentException) {
            invalid = true
        }
    }

    Di("mb-3") {
        Label { +label }
        Row {
            Di("col-auto") {
                Select({
                    classes("form-select")
                    if (invalid) classes("is-invalid")
                    onChange {
                        it.value?.toInt()?.let {
                            day = it
                            tryCreateDate()
                        }
                    }
                }) {
                    for (i in 1..31)
                        Option("$i", {
                            if (i == day) selected()
                        }) { +"$i" }
                }
            }
            Di("col-auto") {
                Select({
                    classes("form-select")
                    onChange {
                        it.value?.toInt()?.let { month = it }
                        tryCreateDate()
                    }
                }) {
                    OptMonth(1, "января", month)
                    OptMonth(2, "февраля", month)
                    OptMonth(3, "марта", month)
                    OptMonth(4, "апреля", month)
                    OptMonth(5, "мая", month)
                    OptMonth(6, "июня", month)
                    OptMonth(7, "июля", month)
                    OptMonth(8, "августа", month)
                    OptMonth(9, "сентября", month)
                    OptMonth(10, "октября", month)
                    OptMonth(11, "ноября", month)
                    OptMonth(12, "декабря", month)
                }
            }
            Di("col-auto") {
                Select({
                    classes("form-select")
                    onChange {
                        it.value?.toInt()?.let { year = it }
                        tryCreateDate()
                    }
                }) {
                    val y0 = nowDate().year
                    for (i in 0..25) {
                        val y = y0 + i
                        val ys = "${y}"
                        Option(ys, { if (y == year) selected() }) { +ys }
                    }
                }
            }
        }
    }
}