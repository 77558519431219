package controls

import Client
import WaitPanel
import androidx.compose.runtime.LaunchedEffect
import net.sergeych.kiloparsec.Command

fun <A,R>WaitRequest(cmd: Command<A,R>,arg: A,completion: (Result<R>)->Unit) {
    Router.openPopup {
        WaitPanel("жду ответа ${cmd.name}($arg)")
        LaunchedEffect(true) {
            try {
                val r = Client.call(cmd, arg)
                completion(Result.success(r))
            }
            catch(t: Throwable) {
                completion(Result.failure(t))
            }
            closePopup()
        }
    }
}