package net.sergeych.morozilko

import kotlinx.datetime.*

class InvalidFormatException(msg: String="invalid data format") : Exception(msg)

/*
 Expiration variants:

 (3 (дня/недели/месяца) ((с/от/после) (дате))
 до (дате)
 (дата)

 При этом дата может быть:

 число.месяц.год
 число.месяц
 число (месяц)
 число


 */

var reFullDateNumbers = Regex("(\\d{1,2})\\.(\\d\\d)\\.(\\d{4}|\\d{2})")
var reFullDateISO = Regex("(\\d{4})-(\\d\\d)-(\\d{2})")

var monthVariants: Map<String, Int> = run {
    listOf(
        "jan january янв январь января",
        "feb february фев февраль февраля",
        "mar march мар март марта",
        "apr april апр апрель апреля",
        "may may май мая",
        "jun june июн июнь июня",
        "jul july июл июль июля",
        "aug august авг август августа",
        "sep september сен сентябрь сентября",
        "oct october окт октябрь октября",
        "nov november ноя ноябрь ноября",
        "dec december дек декабрь декабря"
    ).mapIndexed { i, s ->
        s.split(' ').map { it to i + 1 }
    }.flatten().toMap()
}

val reDateMonth = Regex("(\\d\\d?)\\s+([a-zA-Zа-яА-Я]{3,})?")
val reDayNumber = Regex("\\d\\d?")

/**
 * Декодирует абсолютную дату, используя текущий год и месяц если надо (русский и английский).
 * - цифрами: число.месяц.год (год короткий или длинный)
 * - цифрами ISO: YYYY-MM-DD
 * - цифрами число.месяц (используя текущий год)
 *  число месяц_буквами - аббревиатура (мар) или месяц (8 марта), используя текущий год
 *  число, используя текущий год
 */
fun extractDate(src: String): ExtractionResult<LocalDate>? {
    // simple: число.месяц.год
    reFullDateNumbers.find(src)?.let { m ->
        val mm = m.groupValues.drop(1).map { it.toInt() }
        var year = mm[2]
        if (year < 100) year += 2000
        println(": $mm")
        return ExtractionResult(src, m, LocalDate(year, mm[1], mm[0]))
    }
    reFullDateISO.find(src)?.let { m ->
        val mm = m.groupValues.drop(1).map { it.toInt() }
        return ExtractionResult(src, m, LocalDate(mm[0], mm[1], mm[2]))
    }
    // date (month)
    val now = nowDate()
    reDateMonth.find(src)?.let { m ->
        val day = m.groupValues[1].toInt()
        if (day in 1..31) {
            println(": ${m.groupValues}")
            monthVariants[m.groupValues[2].lowercase()]?.let { month ->
                return ExtractionResult(src, m, LocalDate(now.year, month, day))
            }
        }
        // wrong date or month
    }
    // only day?
    reDayNumber.find(src)?.let { m ->
        val day = m.value.toInt()
        if (day in 1..31) {
            return ExtractionResult(src, m, LocalDate(now.year, now.month, day))
        }
    }
    return null
}

val reDays = Regex("(\\d+)\\s*(?:дней|дня|день|д\\.?)")
val reMonths = Regex("(\\d+)\\s*(?:месяца|месяцев|месяц|мес\\.?|м\\.?)")
val reYears = Regex("(\\d+)\\s*(?:года|лет|год|г\\.?)")

val reFromWord = Regex("\\s*(?:от|после|начиная с|начиная|с)\\s+")

/**
 * Extract either absolute date (as in [extractDate], or relative in respect of another date,
 * this time Russian only:
 *
 * - NN (д|д.|дней|дня|день|м|м.|мес.|мес|месяц|месяца|месяцев|год|года|лет|г.|г) (от|с|после|начиная|начиная с) DATE)
 * - NN (срок) - от текущей даты, срок см выше
 */
fun extractRelativeOrAbsoluteDate(source: String): ExtractionResult<LocalDate>? {
    // find days
    var src = source
    var index = -1
    fun correct(m: MatchResult): Int {
        src = src.removeRange(m.range).replace(reManySpaces, " ")
        index = m.range.first
        return m.groupValues[1].toInt()
    }

    val period = reDays.find(src)?.let { DateTimePeriod(0, 0, correct(it)) }
        ?: reMonths.find(src)?.let { DateTimePeriod(0, correct(it)) }
        ?: reYears.find(src)?.let { DateTimePeriod(correct(it)) }
        ?: return null

    period as DatePeriod

    println("from [$src] from $index")
    // relatove date?
    val fromDate = reFromWord.find(src.lowercase(), index)?.let { m ->
        println("fromW: $m")
        src = src.removeRange(m.range).replace(reManySpaces, " ")
        extractDate(src)?.result ?: throw InvalidFormatException("after mark found but no data after it")
    }
        ?: nowDate()


    return ExtractionResult(fromDate + period, src)
}

fun nowDate() = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date