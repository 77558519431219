import androidx.compose.runtime.Composable
import controls.Di
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.H2
import org.w3c.dom.HTMLDivElement

@Composable
fun Spinner(attr: AttrBuilderContext<HTMLDivElement>?=null) {
    Di("spinner-flake", attr) { }
}

@Composable
fun WaitPanel(text: String) {
    CenterPanel {
        H2({
            style { textAlign("center") }
        }) {
            Spinner()
            +" $text"
        }
    }
}