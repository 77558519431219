package net.sergeych.kiloparsec

import kotlinx.serialization.Serializable
import net.sergeych.crypto2.Seal
import net.sergeych.crypto2.SigningKey

// L0 commands - key exchange and check:
@Serializable
data class Handshake(val version: UInt, val publicKey: UByteArray,
                     val signature: Seal? = null)

@Serializable
data class ClientIdentity(val clientIdKey: SigningKey.Public?, val signature: UByteArray?)

// Level 0 command: request key exchange
internal val L0Request by command<Handshake, Handshake>()
internal val L0ClientId by command<UByteArray, Unit>()
internal val L0Call by command<UByteArray,UByteArray>()
