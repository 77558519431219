package controls

import androidx.compose.runtime.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.TextInput
import tools.randomId
import unaryPlus

@Composable
fun TextField(
    value: String,
    label: String,
    state: InputState = InputState.neutral,
    setter: (String) -> Unit,
) {
    val id = remember { randomId(17) }

    Di("input-group mb-3") {
        Di("form-floating") {
            Input(InputType.Text) {
                defaultValue(value)
                classes("form-control")
                placeholder(label)
                id(id)
                onChange {
                    setter(it.value)
                }
                if (state.isError) classes("is-invalid")
                if (state.isSuccess) classes("is-valid")
            }
            Label(forId = id) { +label }
        }
        if (state.isError) state.errorText?.let {
            Di("invalid-feedback") { +it }
        }
        if (state.isSuccess) state.successText?.let {
            Di("valid-feedback") { +it }
        }
    }
}