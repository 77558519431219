import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import net.sergeych.bintools.defaultNamedStorage
import net.sergeych.bintools.optStored
import net.sergeych.crypto2.initCrypto
import net.sergeych.kiloparsec.Command
import net.sergeych.kiloparsec.KiloClient
import net.sergeych.kiloparsec.KiloInterface
import net.sergeych.kiloparsec.adapter.websocketClient
import net.sergeych.morozilko.*
import net.sergeych.mp_logger.Log
import net.sergeych.mp_tools.globalLaunch

sealed class OnlineState {
    @Suppress("unused")
    val isLoggedIn: Boolean get() = this is LoggedIn

    object Checking : OnlineState()
    object NotLoggedIn : OnlineState()

    class LoggedIn(val user: ApiUser) : OnlineState()
}

object Client {

    private val _onlineStateFlow = MutableStateFlow<OnlineState>(OnlineState.Checking)
    val onlineStateFlow = _onlineStateFlow.asStateFlow()

    var onlineState by mutableStateOf<OnlineState>(_onlineStateFlow.value)
        private set
    private val client = CompletableDeferred<KiloClient<Unit>>()

    suspend fun <A, R> call(cmd: Command<A, R>, args: A): R =
        client.await().call(cmd, args)

    suspend fun <A, R> callCatching(cmd: Command<A, R>, args: A): Result<R> =
        kotlin.runCatching { client.await().call(cmd, args) }

    suspend fun <R> call(cmd: Command<Unit, R>): R =
        client.await().call(cmd)

    private val _connectedFlow = MutableStateFlow(false)

    @Suppress("unused")
    val connectedFlow = _connectedFlow.asStateFlow()

    val storage = defaultNamedStorage("morozilko")
    private var accessToken: String? by storage.optStored()

    var me: ApiUser? by mutableStateOf<ApiUser?>(null)
        private set

    private suspend fun tryActivateAuthToken() {
        var authToken = window.location.hash
        if (authToken == "") {
            _onlineStateFlow.value = OnlineState.NotLoggedIn
            return
        }
        authToken = if (authToken.startsWith('#')) authToken.drop(1) else authToken
        call(cmdActivateAuthToken, authToken)?.let { u ->
            accessToken = u.accessToken
            _onlineStateFlow.value = OnlineState.LoggedIn(u)
            window.location.hash = ""
        } ?: run {
            // todo: show Toast, Snackbar or like
            println("token activation failed")
            _onlineStateFlow.value = OnlineState.NotLoggedIn
        }
    }

    fun logout() {
        globalLaunch {
            call(cmdLogout)
            accessToken = null
            _onlineStateFlow.value = OnlineState.NotLoggedIn
        }
    }

    init {
        globalLaunch {
            onlineStateFlow.collect {
                me = if (it is OnlineState.LoggedIn) {
                    globalLaunch { callCatching(cmdUpdateTzOffset, currentOffsetInSeconds()) }
                    it.user
                } else
                    null
                onlineState = it
            }
        }
        globalLaunch {
            try {
                initCrypto()
                Log.defaultLevel = Log.Level.DEBUG
                Log.connectConsole(Log.Level.DEBUG)
                val c = websocketClient<Unit>(
//                    if ("127.0.0.1" in window.location.host)
//                        "ws://localhost:8082/kp"
//                    else
                        "wss://morozilko.sergeych.net",
                    KiloInterface<Unit>().also { it.addErrorProvider(errorProvider) }
                )
                client.complete(c)
                launch {
                    val v = call(cmdVersion)
                    console.log("service version", v)
                }
                println("connection set, keeping connection flow")
                c.state.collect {
//                    println("*********** state $it")
                    _connectedFlow.value = it
                    if (it) {
                        accessToken?.let { t ->
                            // connected, restore login state
                            call(cmdLogin, t)?.let {
                                _onlineStateFlow.value = OnlineState.LoggedIn(it)
                            }
                                ?: run {
                                    println("token is invalid, dropping it")
                                    accessToken = null
                                    tryActivateAuthToken()
                                }
                        } ?: run {
                            tryActivateAuthToken()
                        }
                        window.location.hash = ""

                    } else {
                        _onlineStateFlow.value = OnlineState.Checking
                    }
                }
            } catch (t: Throwable) {
                println("can't get the client instance")
                t.printStackTrace()
            }
        }
    }


}