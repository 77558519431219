package net.sergeych.kiloparsec

/**
 * The local interface to provice functions, register errors for Kiloparsec users. Use it
 * with [KiloClient], [KiloClientConnection], [KiloServerConnection], etc.
 *
 * BAse implementation registers relevant exceptions.
 */
class KiloInterface<S> : LocalInterface<KiloScope<S>>() {

    internal var onConnectHandler: (KiloScope<S>.()->Unit) ? = null

    fun onConnected(f: KiloScope<S>.()->Unit) { onConnectHandler = f }

    init {
        registerError { RemoteInterface.UnknownCommand() }
        registerError { RemoteInterface.ClosedException(it) }
        registerError { RemoteInterface.SecurityException(it) }
        registerError { RemoteInterface.InvalidDataException(it) }
        registerError { RemoteInterface.RemoteException(it) }
        registerError { IllegalStateException() }
        registerError { IllegalArgumentException(it) }
    }
}

