package net.sergeych.kiloparsec

import kotlinx.coroutines.CompletableDeferred
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.Loggable
import net.sergeych.utools.unpack

private var L1IdCounter = 0

/**
 * Represents a remote interface for interacting with a Kiloparsec service.
 *
 * @param S the scope type of the Kiloparsec service which will be used to call local functions remotely
 * @property deferredParams a [CompletableDeferred] that resolves to the parameters required for making remote calls
 * @property clientInterface a [LocalInterface] used to communicate with the local client
 */
class KiloRemoteInterface<S>(
    private val deferredParams: CompletableDeferred<KiloParams<S>>,
    private val clientInterface: LocalInterface<KiloScope<S>>,
) : RemoteInterface, Loggable by LogTag("L1TR:${++L1IdCounter}") {

    override suspend fun <A, R> call(cmd: Command<A, R>, args: A): R {
        val params = deferredParams.await()
        val block: Transport.Block = unpack(
            params.decrypt(
                params.transport.call(L0Call, params.encrypt(cmd.packCall(args)))
            )
        )
        return when (block) {
            is Transport.Block.Response -> {
                cmd.unpackResult(block.packedResult)
            }

            is Transport.Block.Error -> clientInterface.decodeAndThrow(block)
            else -> throw RemoteInterface.Exception("unexpected block type: $block")
        }
    }
}

