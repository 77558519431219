import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import controls.*
import kotlinx.datetime.LocalDate
import kotlinx.datetime.daysUntil
import net.sergeych.morozilko.ApiItem
import net.sergeych.morozilko.ApiUser
import net.sergeych.morozilko.cmdListItems
import net.sergeych.morozilko.nowDate
import net.sergeych.sprintf.sprintf
import org.jetbrains.compose.web.dom.*

@Composable
fun UserHome(user: ApiUser) {
    val items = mutableStateListOf<ApiItem>()
    Di("center-content") {
        Heading("Ваша муррозилка:")

        Btn("outline-primary my-3", { Router.push { AddItem() } }) { +"добавить запись" }

        val now = nowDate()
        if (items.isEmpty())
            P { +"Ваша муррозилка пуста, добавьте запись, кнопка выше." }
        else {
            Table({ classes("table","table-striped","table-hover") }) {
                Thead {
                    Tr {
                        Th { +"назв." }
                        Th { +"кол." }
                        Th { +"годн." }
                        Th({classes("d-none", "d-sm-table-cell")}) { +"нап." }
                        Th({classes("d-none", "d-sm-table-cell")}) { +"ID" }
                    }
                }
                Tbody {
                    for (i in items) {
                        val d = now.daysUntil(i.expiresAt)
                        Tr({
                            when {
                                d <= 3 -> classes("table-danger")
                                d <=10 -> classes("table-warning")
                                else -> {}
                            }
                            onClick { Router.push { EditItem(i) } }
                        }) {
                            Td { +i.name }
                            Td { +i.qty }
                            Td {
                                when {
                                    d <= 0 -> +"истек ${i.expiresAt.short()}"
                                    d < 30 -> +"eщё $d дн. до ${i.expiresAt.short()}"
                                    else -> +"до ${i.expiresAt.short()}"
                                }
                            }
                            Td({classes("d-none", "d-sm-table-cell")}) {
                                i.notifyAt?.let { +it.short() }
                            }
                            Td({classes("d-none", "d-sm-table-cell")}) {
                                +i.id.toString()
                            }
                        }
                    }
                }
            }
        }
    }
    LaunchedEffect(true) {
        WaitRequest(cmdListItems, Unit) {
            if (it.isSuccess) {
                items.clear()
                items.addAll(it.getOrThrow())
            } else {
                Router.notifyError("не удалось загрузить список")
            }
        }
    }
}

private fun LocalDate.short(): String =
    "%02d.%02d.%02d".sprintf(
        dayOfMonth, monthNumber, year - 2000
    )


@Composable
operator fun String.unaryPlus() {
    Text(this)
}