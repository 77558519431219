package net.sergeych.morozilko

val reManySpaces = Regex("\\s+")

data class ExtractionResult<T>(
    val result: T,
    val processedSource: String
) {
    constructor(src: String,match: MatchResult,result: T) : this(
        result,
        src.removeRange(match.range).replace(reManySpaces, " ")
    )
}