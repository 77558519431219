package net.sergeych.morozilko

import net.sergeych.kiloparsec.LocalInterface

open class ApiError(msg: String): Exception(msg)

class NotLoggedInError: ApiError("login required")
class ItemCreationException(msg: String="can't create item"): ApiError(msg)

@Suppress("unused")
val errorProvider = LocalInterface<Unit>().apply {
    registerError { ApiError(it) }
    registerError { NotLoggedInError() }
    registerError { ItemCreationException() }
}