package net.sergeych.kiloparsec.adapter

import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.channels.ReceiveChannel
import kotlinx.coroutines.channels.SendChannel
import net.sergeych.kiloparsec.Transport
import net.sergeych.tools.AtomicCounter

private val counter = AtomicCounter()
open class ProxyDevice(
    inputChannel: Channel<UByteArray>,
    outputChannel: Channel<UByteArray>,
    private val onClose: suspend ()->Unit = {}): Transport.Device {

    override val input: ReceiveChannel<UByteArray> = inputChannel
    override val output: SendChannel<UByteArray> = outputChannel
    override suspend fun close() {
        onClose()
    }

    private val id = counter.incrementAndGet()

    override fun toString(): String = "PX$id"
}