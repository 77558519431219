package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

@Composable
fun Di(
    classNames: String,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    block: ContentBuilder<HTMLDivElement>,
) {
    Div({
        classes(classNames.split(' ').map { it.trim() })
        attrs?.invoke(this)
    }, block)
}