package net.sergeych.crypto2

import com.ionspin.kotlin.crypto.signature.InvalidSignatureException
import com.ionspin.kotlin.crypto.signature.Signature
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import net.sergeych.crypto2.SigningKey.Secret

/**
 * Keys in general: public, secret and later symmetric too.
 * Keys could be compared to each other for equality and used
 * as a Map keys (not sure about js).
 *
 * Use [pair] to create new keys.
 */
@Serializable
sealed class SigningKey {
    abstract val packed: UByteArray

    override fun equals(other: Any?): Boolean {
        return other is SigningKey && other.packed contentEquals packed
    }

    override fun hashCode(): Int {
        return packed.contentHashCode()
    }

    override fun toString(): String = packed.encodeToBase64Url()

    /**
     * Public key to verify signatures only
     */
    @Serializable
    @SerialName("p")
    class Public(override val packed: UByteArray) : SigningKey() {
        /**
         * Verify the signature and return true if it is correct.
         */
        fun verify(signature: UByteArray, message: UByteArray): Boolean = try {
            Signature.verifyDetached(signature, message, packed)
            true
        } catch (_: InvalidSignatureException) {
            false
        }

        override fun toString(): String = "Pub:${super.toString()}"

    }

    /**
     * Secret key to sign only
     */
    @Serializable
    @SerialName("s")
    class Secret(override val packed: UByteArray) : SigningKey() {

        val publicKey: Public by lazy {
            Public(Signature.ed25519SkToPk(packed))
        }

        fun sign(message: UByteArray): UByteArray = Signature.detached(message, packed)

        fun seal(message: UByteArray): Seal = Seal(this.publicKey, sign(message))
        override fun toString(): String = "Sct:${super.toString()}"

    }

    companion object {
        data class Pair(val secretKey: Secret, val publicKey: Public)

        fun pair(): Pair {
            val p = Signature.keypair()
            return Pair(Secret(p.secretKey), Public(p.publicKey))
        }
    }
}

class IllegalSignatureException: RuntimeException("signed data is tampered or signature is corrupted")
