package net.sergeych.kiloparsec

import net.sergeych.utools.firstNonNull
import kotlin.reflect.KClass

private typealias RawCommandHandler<C> = suspend (C, UByteArray) -> UByteArray

open class LocalInterface<S> {

    private val commands = mutableMapOf<String, RawCommandHandler<S>>()

    /**
     * New session creator. Rarely needed directlym it can be used for delegation
     * of local interfaces.
     */
//    var sessionMaker: suspend () -> S = {
//        @Suppress("UNCHECKED_CAST")
//        Unit as? S ?: throw IllegalStateException("newSession handler is not set")
//    }
//        private set

//    /**
//     * Builder-style method to create session. Sets the [sessionMaker] actually.
//     */
//    fun createSession(sessionMaker: suspend () -> S) {
//        this.sessionMaker = sessionMaker
//    }

    /**
     * Define a command body to be executed locally.
     */
    fun <A, R> on(command: Command<A, R>, handler: suspend S.(A) -> R) {
        commands[command.name] = { cxt, args ->
            command.exec(args) { handler(cxt, it) }
        }
    }

    suspend fun execute(
        scope: S,
        name: String,
        packedArgs: UByteArray,
    ): UByteArray =
        (commands[name] ?: throw RemoteInterface.UnknownCommand())
            .invoke(scope, packedArgs)


    private val errorByClass = mutableMapOf<KClass<*>, String>()
    private val errorBuilder = mutableMapOf<String, (String, UByteArray?) -> Throwable>()

    fun <T : Throwable> registerError(
        klass: KClass<T>, code: String = klass.simpleName!!,
        exceptionBuilder: (String, UByteArray?) -> T,
    ) {
        errorByClass[klass] = code
        errorBuilder[code] = exceptionBuilder
    }

    inline fun <reified T : Throwable> registerError(
        noinline exceptionBuilder: (String) -> T,
    ) {
        registerError(T::class) { msg, _ -> exceptionBuilder(msg) }
    }

    val errorProviders = mutableListOf<LocalInterface<*>>()

    fun <I : LocalInterface<*>> addErrorProvider(provider: I) {
        errorProviders += provider
    }

    fun getErrorCode(t: Throwable): String? =
        errorByClass[t::class] ?: errorProviders.firstNonNull { it.getErrorCode(t) }

    fun encodeError(forId: UInt, t: Throwable): Transport.Block.Error =
        getErrorCode(t)?.let { Transport.Block.Error(forId, it, t.message) }
            ?: Transport.Block.Error(forId, "UnknownError", t.message)

    open fun getErrorBuilder(code: String): ((String, UByteArray?) -> Throwable)? =
        errorBuilder[code] ?: errorProviders.firstNonNull { it.getErrorBuilder(code) }

    fun decodeError(tbe: Transport.Block.Error): Throwable =
        getErrorBuilder(tbe.code)?.invoke(tbe.message, tbe.extra)
            ?: RemoteInterface.RemoteException(tbe)

    fun decodeAndThrow(tbe: Transport.Block.Error): Nothing {
        throw decodeError(tbe)
    }


}
