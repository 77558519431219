package net.sergeych.morozilko

import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.offsetIn

fun currentOffsetInSeconds(): Int {
    val tz = TimeZone.currentSystemDefault()
    val now = Clock.System.now()
    val n1 = now.offsetIn(tz).totalSeconds
    return n1
}