package net.sergeych.bintools

import kotlin.reflect.typeOf

/**
 * The common interface to whatever variable (or even fixed) length integer encoder.
 * Implementation can just override [encodeUnsigned] and [decodeUnsigned] pair and
 * get the rest (incliding signed codec) out of the box. DRY for [Smartint] and [Varint]
 * codecs.
 */
interface IntCodec {
    fun encodeUnsigned(value: ULong,sink: DataSink)

    fun decodeUnsigned(source: DataSource): ULong

    /**
     * Default signed codec uses bit 0 as a sign (to keep packed as small as possible)
     */
    fun encodeSigned(value: Long, sink: DataSink) {
        var sigBit: ULong
        var x: ULong
        if (value < 0) {
            x = (-value).toULong()
            sigBit = 1u
        } else {
            x = value.toULong()
            sigBit = 0u
        }
        encodeUnsigned((x shl 1) or sigBit, sink)
    }

    /**
     * Default signed codec uses bit 0 as a sign (to keep packed as small as possible)
     */
    fun decodeSigned(source: DataSource): Long {
        val x = decodeUnsigned(source)
        val result = (x shr 1).toLong()
        return if ((x and 1u).toInt() != 0) -result else result
    }

    fun encodeUnsigned(value: ULong): ByteArray {
        return ArrayDataSink().also { encodeUnsigned(value, it) }.toByteArray()
    }

    fun decodeUnsigned(packed: ByteArray) = decodeUnsigned(packed.toDataSource())

    fun encodeSigned(value: Long): ByteArray {
        return ArrayDataSink().also { encodeSigned(value, it) }.toByteArray()
    }

    fun decodeSigned(data: ByteArray): Long {
        return decodeSigned(data.toDataSource())
    }
}

inline fun <reified T : Any> IntCodec.decode(source: ByteArray): T {
    return decode(source.toDataSource())
}

inline fun <reified T : Any> IntCodec.decode(source: DataSource): T {
    return when (typeOf<T>()) {
        typeOf<UByte>() -> decodeUnsigned(source).toUByte()
        typeOf<UInt>() -> decodeUnsigned(source).toUInt()
        typeOf<UShort>() -> decodeUnsigned(source).toUShort()
        typeOf<ULong>() -> decodeUnsigned(source).toULong()
        typeOf<Byte>() -> decodeSigned(source).toByte()
        typeOf<Int>() -> decodeSigned(source).toInt()
        typeOf<Short>() -> decodeSigned(source).toShort()
        typeOf<Long>() -> decodeSigned(source).toLong()
        else ->
            throw IllegalArgumentException("can't decode to ${T::class.simpleName}")
    } as T
}

inline fun <reified T : Any> IntCodec.encode(x: T, dout: DataSink) {
    when (x) {
        is UByte -> encodeUnsigned(x.toULong(), dout)
        is UInt -> encodeUnsigned(x.toULong(), dout)
        is UShort -> encodeUnsigned(x.toULong(), dout)
        is ULong -> encodeUnsigned(x, dout)
        is Byte -> encodeSigned(x.toLong(), dout)
        is Int -> encodeSigned(x.toLong(), dout)
        is Short -> encodeSigned(x.toLong(), dout)
        is Long -> encodeSigned(x, dout)
        else -> throw IllegalArgumentException("can't encode with varitn ${x::class.simpleName}: $x")
    }
}

inline fun <reified T : Any> IntCodec.encode(x: T): ByteArray =
    ArrayDataSink().also { encode(x, it) }.toByteArray()
