package net.sergeych.kiloparsec

import kotlinx.serialization.KSerializer
import kotlinx.serialization.serializer
import kotlin.reflect.KProperty

/**
 * delegate returning function that creates a [Command] in the current context which by default has the name of
 * the property.
 *
 * The Default name is a property name except the "cmd" prefix if present, which will be
 * removed automatically.
 */
inline fun <reified A, reified R> command(overrideName: String? = null): CommandDelegate<A, R> {
    return CommandDelegate(
        serializer<A>(),
        serializer<R>(),
        overrideName
    )
}

/**
 * Delegate to create [Command] via property
 */
class CommandDelegate<A, R>(
    private val argsSerializer: KSerializer<A>,
    private val resultSerializer: KSerializer<R>,
    private val overrideName: String? = null,
) {
    private var name: String = ""
    operator fun getValue(nothing: Nothing?, property: KProperty<*>): Command<A, R> {
        if (name.isEmpty()) {
            name = overrideName ?: removeCmd(property.name)
        }
        return Command(
            name,
            argsSerializer,
            resultSerializer
        )
    }
}

private fun removeCmd(name: String) =
    if (name.startsWith("cmd"))
        name.substring(3)
    else name
