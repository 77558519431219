package net.sergeych.bintools

/**
 * The filter-type sink that calculates CRC32 on the fly passing data to
 * another sink
 */
class CRC32Sink(val sink: DataSink,
                polynomial:UInt = 0x04C11DB7U): DataSink {

    private val checksum = CRC32(polynomial)
    override fun writeByte(data: Byte) {
        checksum.update(data.toUByte())
        sink.writeByte(data)
    }

    override fun writeBytes(data: ByteArray) {
        checksum.update(data.toUByteArray())
        sink.writeBytes(data)
    }

    /**
     * current value of the CRC32 checkcum, using the bytes that
     * were written by now.
     */
    val crc: UInt get() = checksum.value
}