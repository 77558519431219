package net.sergeych.bintools

/**
 * data input stream-like abstraction. We need it because
 * kotlinx serialization is synchronous and there us nothing
 * like multiplatform version of DataInput
 *
 */
@Suppress("unused")
interface DataSource {

    /**
     * Exception that implementations must throw on end of data
     */
    class EndOfData : Exception("no more data available")

    fun readByte(): Byte

    /**
     * true if there is no more data available and next read operation will surely
     * throw EndOfData. Can return null if it is impossible to determine (for some
     * async sources)
     */
    fun isEnd(): Boolean? = null

    fun readUByte() = readByte().toUByte()

    @Suppress("unused")
    fun readBytes(size: Int): ByteArray {
        return ByteArray(size).also { a ->
            for (i in 0 until size)
                a[i] = readByte()
        }
    }

    fun readU32(): UInt = bytesToUInt(readBytes(4))
    fun readI32(): Int = bytesToInt(readBytes(4))
    fun readI16(): Short = bytesToShort(readBytes(2))
    fun readI64(): Long = bytesToLong(readBytes(8))

    fun readDouble() = Double.fromBits(readI64())

    fun readFloat() = Float.fromBits(readI32())

    fun readSmartUInt(): UInt = Smartint.decodeUnsigned(this).toUInt()
    fun readSmartInt(): Int = Smartint.decodeSigned(this).toInt()

    fun readVarUInt(): UInt = Varint.decodeUnsigned(this).toUInt()
    fun readVarInt(): Int = Varint.decodeSigned(this).toInt()



}
fun ByteArray.toDataSource(): DataSource =
    object : DataSource {
        var position = 0
            private set

        @Suppress("RedundantNullableReturnType")
        override fun isEnd(): Boolean? = position == size

        override fun readByte(): Byte =
            if (position < size) this@toDataSource[position++]
            else throw DataSource.EndOfData()

        override fun toString(): String {
            return "ASrc[$position]: ${encodeToHex()}"
        }
    }

@Suppress("unused")
fun UByteArray.toDataSource(): DataSource =
    object : DataSource {
        var position = 0
            private set

        @Suppress("RedundantNullableReturnType")
        override fun isEnd(): Boolean? = position == size

        override fun readByte(): Byte =
            if (position < size) this@toDataSource[position++].toByte()
            else throw DataSource.EndOfData()

        override fun toString(): String {
            return "ASrc[$position]: ${encodeToHex()}"
        }
    }

inline fun <reified T : Any> DataSource.readNumber(): T = Smartint.decode(this) as T

