package net.sergeych.bintools

interface DataSink {

    fun writeByte(data: Byte)

    fun writeByte(data: Int) = writeByte(data.toByte())

    fun writeUByte(data: UByte) {
        writeByte(data.toByte())
    }

    fun writeDouble(value: Double) {
        writeI64(value.toRawBits())
    }

    fun writeFloat(value: Float) {
        writeI32(value.toRawBits())
    }

    @Suppress("unused")
    fun writeBytes(data: ByteArray) {
        for(d in data) writeByte(d)
    }

    fun writeVarUInt(value: UInt) { Varint.encodeUnsigned(value.toULong(), this)}
    fun writeVarInt(value: UInt) { Varint.encodeSigned(value.toLong(), this)}
    fun writeSmartUInt(value: UInt) { Smartint.encodeUnsigned(value.toULong(), this)}
    fun writeSmartInt(value: UInt) { Smartint.encodeSigned(value.toLong(), this)}
}

inline fun <reified T:Any>DataSink.writeNumber(value: T) {
    Smartint.encode(value, this)
}

fun DataSink.writeI32(value: Int) {
    writeBytes(intToBytes(value))
}

fun DataSink.writeI16(value: Short) {
    writeBytes(shortToBytes(value))
}
fun DataSink.writeU32(value: UInt) {
    writeBytes(uintToBytes(value))
}

fun DataSink.writeI64(value: Long) {
    writeBytes(longToBytes(value))
}


class ArrayDataSink : DataSink {
    private val result = mutableListOf<Byte>()

    override fun writeByte(data: Byte) {
        result.add(data)
    }

    fun toByteArray() = result.toByteArray()
}
