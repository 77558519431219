package net.sergeych.morozilko

import kotlinx.serialization.Serializable

@Suppress("unused")
@Serializable
class ApiUser(
    val firstName: String?,
    val lastName: String?,
    val userName: String?,
    val accessToken: String?=null,
    val id: Long = 0
) {
    val isAnonymous by lazy {
        firstName == null && lastName == null
    }

    val fullName by lazy {
        val n = "${firstName ?: ""} ${lastName ?: ""}"
            .replace(reManySpaces, " ")
            .trim()
        if( n.isEmpty() ) "неизвестный" else n
    }
}